import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import React from 'react';
import Firmwares from '../../../../common/assets/firmware-updates.jpg';
import Logs from '../../../../common/assets/lego-logs.jpg';
import Devices from '../../../../common/assets/lego-router.jpg';
import UntrustedDevices from '../../../../common/assets/untrusted-devices.jpg';
import { ToolWidget } from '../../../../components/atoms/ToolWidget';
export function InfraView() {
    return (_jsxs(Grid, { container: true, children: [_jsx(ToolWidget, { title: "Untrusted devices", description: "Add unknown devices.", image: UntrustedDevices, link: '/infra/untrusted-devices' }), _jsx(ToolWidget, { title: "Devices", description: "Manage your devices.", image: Devices, link: '/infra/devices' }), _jsx(ToolWidget, { title: "Logs", description: "See logs from all devices.", image: Logs, link: '/infra/logs' }), _jsx(ToolWidget, { title: "Firmwares", description: "Upload firmwares.", image: Firmwares, link: '/infra/firmwares' })] }));
}
