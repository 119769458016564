import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import React from 'react';
import Graph from '../../../../common/assets/bar-graph-vector-icon.jpg';
import Programming from '../../../../common/assets/computer-programming.jpg';
import Keys from '../../../../common/assets/keys.jpg';
import Logs from '../../../../common/assets/logs.jpg';
import Queues from '../../../../common/assets/queues.jpg';
import { ToolWidget } from '../../../../components/atoms/ToolWidget';
export function ToolsView() {
    return (_jsxs(Grid, { container: true, children: [_jsx(ToolWidget, { title: 'Statistics', description: 'See real-time statistic from the server.', image: Graph, link: '/tools/stats' }), _jsx(ToolWidget, { title: 'Queues', description: 'See status of queues in rabbitmq.', image: Queues, link: '/tools/queues' }), _jsx(ToolWidget, { title: 'Keys', description: 'Configure API keys for rabbit MQ.', image: Keys, link: '/tools/keys' }), _jsx(ToolWidget, { title: 'IR Frame decoder', description: 'Decipher IR frames.', image: Programming, link: '/tools/frames' }), _jsx(ToolWidget, { title: 'Rigado log decoder', description: 'Decipher Rigado logs.', image: Logs, link: '/tools/rigado' })] }));
}
