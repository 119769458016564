import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { PricerPagination } from '../../../../../components/atoms/PricerPagination';
import { DeviceListItem } from '../atoms/DeviceListItem';
export function ListItemView(_a) {
    var devices = _a.devices, page = _a.page, setPage = _a.setPage;
    var navigator = useNavigate();
    var onClick = function (device) {
        return navigator("/infra/devices/".concat(device.id));
    };
    var onDelete = function (device) {
        return navigator("/infra/devices/".concat(device.id, "/delete"));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { container: true, spacing: 2, children: devices.content.map(function (c) { return (_jsx(DeviceListItem, { device: c, onClick: onClick, onDelete: onDelete }, c.id)); }) }), _jsx(PricerPagination, { items: devices, page: page, setPage: setPage })] }));
}
